import Script from 'next/script';
import { memo } from 'react';

const GoogleTagManager = () => {
  return process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? (
    // eslint-disable-next-line react/jsx-filename-extension
    <Script
      id="tagmanager"
      strategy="lazyOnload"
    >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NGGJVX2');`}</Script>
  ) : null;
};

export default memo(GoogleTagManager);
